@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&family=Open+Sans&family=Roboto&display=swap');

.Masthead h1 {
  font-family: "Roboto", sans-serif;
  color: white;
}


.Header-link {
  font-size: 24px !important;
  font-family: "Roboto", sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
}

.Header-link:hover {
  color: white;
}

.Header-link[aria-current]:not([aria-current=false]) {
  color: white;
  font-weight: 800 !important;
}

.CircleBadge {
  height: 200px !important;
  width: 200px !important;
  border: 5px solid white !important;
}

.markdown-alert {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  color: inherit;
  border-left: .25em solid #30363d;
}

.markdown-alert> :first-child {
  margin-top: 0;
}

.markdown-alert> :last-child {
  margin-bottom: 0;
}

.markdown-alert .markdown-alert-title {
  display: flex;
  font-weight: 500;
  align-items: center;
  line-height: 1;
}

.markdown-alert svg {
  margin-right: 0.5rem !important;
}

.markdown-alert svg path {
  fill: currentColor;
}

.markdown-alert.markdown-alert-note,
.note {
  border-left-color: #4493f8;
}

.markdown-alert.markdown-alert-note .markdown-alert-title {
  color: #4493f8;
}

.markdown-alert.markdown-alert-important {
  border-left-color: #ab7df8;
}

.markdown-alert.markdown-alert-important .markdown-alert-title {
  color: #ab7df8;
}

.markdown-alert.markdown-alert-warning {
  border-left-color: #9e6a03;
}

.markdown-alert.markdown-alert-warning .markdown-alert-title {
  color: #d29922;
}

.markdown-alert.markdown-alert-tip {
  border-left-color: #238636;
}

.markdown-alert.markdown-alert-tip .markdown-alert-title {
  color: #3fb950;
}

.markdown-alert.markdown-alert-caution {
  border-left-color: #da3633;
}

.markdown-alert.markdown-alert-caution .markdown-alert-title {
  color: #f85149;
}
